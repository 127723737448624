import React from 'react';
import classNames from 'classnames';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Button from 'components/ui-components-v2/Button';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import VisualStackEditorToolboxMore from './toolbox-more';
import '../styles/toolbox.scss';

/**
 * Display the floating toolbox
 * @param {*} param0
 * @returns
 */
const VisualStackEditorToolbox = ({
    className,
    onOpenAddBlockDialog,
    onMoveBlockKeys,
    onDelete,
    onCopy,
    onExportToCreativeManagement,
    activeBlock,
    activeFirst,
    activeLast,
    canMove,
    canRemove,
    canAdd
}) => {
    if (!activeBlock) return null;
    return (
        <div id="visual-stack-editor-toolbox" className={classNames('visual-stack-editor-toolbox', className)}>
            <Tooltip title={Translation.get('visualStackEditor.toolbox.add', 'editor')}>
                <span>
                    <Button
                        onClick={() => onOpenAddBlockDialog()}
                        variant="contained"
                        color="primary"
                        classes={{
                            root: classNames('visual-stack-editor-toolbox__btn', 'visual-stack-editor-toolbox__btn--first')
                        }}
                        disabled={!canAdd}>
                        <Icon>add</Icon>
                    </Button>
                </span>
            </Tooltip>
            <Tooltip title={Translation.get('visualStackEditor.toolbox.up', 'editor')}>
                <span>
                    <Button
                        onClick={() => onMoveBlockKeys(-1)}
                        classes={{ root: classNames('visual-stack-editor-toolbox__btn', 'visual-stack-editor-toolbox__btn--middle') }}
                        disabled={!canMove || activeFirst}>
                        <Icon>keyboard_arrow_up</Icon>
                    </Button>
                </span>
            </Tooltip>
            <Tooltip title={Translation.get('visualStackEditor.toolbox.down', 'editor')}>
                <span>
                    <Button
                        onClick={() => onMoveBlockKeys(1)}
                        classes={{ root: classNames('visual-stack-editor-toolbox__btn', 'visual-stack-editor-toolbox__btn--middle') }}
                        disabled={!canMove || activeLast}>
                        <Icon>keyboard_arrow_down</Icon>
                    </Button>
                </span>
            </Tooltip>
            <VisualStackEditorToolboxMore canAdd={canAdd} onCopy={onCopy} onExportToCreativeManagement={onExportToCreativeManagement} />
            <Tooltip title={Translation.get('visualStackEditor.toolbox.remove', 'editor')}>
                <span>
                    <Button
                        onClick={() => onDelete()}
                        classes={{
                            root: classNames('visual-stack-editor-toolbox__btn', 'visual-stack-editor-toolbox__btn--last')
                        }}
                        disabled={!canRemove}>
                        <Icon>delete</Icon>
                    </Button>
                </span>
            </Tooltip>
        </div>
    );
};

export default VisualStackEditorToolbox;
