import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Button from 'components/ui-components-v2/Button';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import Menu from 'components/ui-components-v2/Menu';
import MenuItem from 'components/ui-components-v2/MenuItem';
import { canExportCreatives } from 'components/creative-management-v2/utilities';

interface Props {
    canAdd: boolean;
    onCopy?: () => void;
    onExportToCreativeManagement?: () => void;
}

const VisualStackEditorToolboxMore = ({ canAdd, onCopy, onExportToCreativeManagement }: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    const exportEnabled = useMemo(() => onExportToCreativeManagement && canExportCreatives(), [onExportToCreativeManagement]);

    const buttonsToShow = (() => {
        if (exportEnabled && onCopy) return 2;
        if (!exportEnabled && !onCopy) return 0;
        return 1;
    })();

    if (!buttonsToShow) return null;

    const doCopy = () => {
        if (!onCopy) return;
        onCopy();
        setAnchorEl(undefined);
    };

    if (buttonsToShow === 1 && onCopy) {
        return (
            <Tooltip title={Translation.get('visualStackEditor.toolbox.copy', 'editor')}>
                <span>
                    <Button
                        onClick={() => onCopy()}
                        className={classNames('visual-stack-editor-toolbox__btn', 'visual-stack-editor-toolbox__btn--middle')}
                        disabled={!canAdd}>
                        <Icon>file_copy</Icon>
                    </Button>
                </span>
            </Tooltip>
        );
    }

    if (buttonsToShow === 1 && onExportToCreativeManagement) {
        return (
            <Tooltip title={Translation.get('visualStackEditor.toolbox.copy', 'editor')}>
                <span>
                    <Button
                        onClick={() => onExportToCreativeManagement()}
                        className={classNames('visual-stack-editor-toolbox__btn', 'visual-stack-editor-toolbox__btn--middle')}>
                        <Icon>star</Icon>
                    </Button>
                </span>
            </Tooltip>
        );
    }

    return (
        <React.Fragment>
            <Button
                onClick={(e) => setAnchorEl(e.currentTarget)}
                className={classNames('visual-stack-editor-toolbox__btn', 'visual-stack-editor-toolbox__btn--middle')}>
                <Icon>more_horiz</Icon>
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(undefined)}>
                <MenuItem onClick={() => doCopy()} disabled={!canAdd}>
                    <ListItemIcon>
                        <Icon>content_copy</Icon>
                    </ListItemIcon>
                    <ListItemText>{Translation.get('visualStackEditor.toolbox.copy', 'editor')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => onExportToCreativeManagement && onExportToCreativeManagement()}>
                    <ListItemIcon>
                        <Icon>star</Icon>
                    </ListItemIcon>
                    <ListItemText>{Translation.get('labels.exportToCreativeManagement', 'creative-management')}</ListItemText>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};

export default VisualStackEditorToolboxMore;
